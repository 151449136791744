import React from 'react'

const Article2 = (props) => {
    return (
        <div>
            <div className="container-fluid temple-join">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-6  col-xl-6 col-lg-6 bg-white temple-join-cta mb-5">
                            <p className="req-to-join">{props.text}</p>
                            <h1 className="fw-light">{props.title}</h1>
                            <p className="fs-4  fw-light">
                                {props.body}
                            </p>
                            <button type="submit" className="btn fs-5  view-temple-btn fw-light begin-rounded end-rounded text-white">
                               {props.subtitle}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Article2