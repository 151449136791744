import React from 'react';
import Home from './Home';
import './App.css';
import './Responsive.css';
import Details1 from './Details1';
import Details2 from './Details2';
import Donate from './Donate';
import Schedule from './Schedule';
import { Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className='App'>
    
      <Routes>

        <Route path="/" element={<Home />} />
        <Route path="/details1" element={<Details1 />} />
        <Route path="/details2" element={<Details2 />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/schedule" element={<Schedule />} />
      </Routes>

    </div>

  );
}

export default App;
