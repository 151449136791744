import React from 'react'
import { Link, Router } from 'react-router-dom'
import Header from './Header'
import './Donate.css';
const Donate = () => {
    return (
        <div>

            <Header />

            <div className="container-fluid">
                <div className="container ">
                    <h1 className="fw-light text-center  mt-4">Donate</h1>
                    <h4 className="fw-light text-orange text-center text-yellow ">How it works</h4>
                </div>
            </div>
            <div className="container-fluid bg-yellow">
                <div className="container p-4">
                    <div className="text-center">
                    <img src="images/44.webp" alt="..." height="50px"/>
                        <h1 className="fs-1 fw-normal">Velneshwar Mandir</h1>
                        <p className="fs-5 fw-light">Velneshwar, Ratnagiri, Maharashtra</p>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="container mt-5 mb-5">
                    <h1 className="fw-light">Select Deity/Devata</h1>
                    <p className="fw-light fs-5 mb-0">Mahadev/Shankar - Shree Velneshwar</p>
                    <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example">
                        <option selected></option>
                        <option value="1">Mahadev/Shankar - Shree Velneshwar</option>
                        <option value="2">Shani Mandir</option>
                    </select>

                    <h1 className="fw-light mt-5  amount">Enter Amount (min 100) <sup>*</sup></h1>
                    <input type="value" className="form-control" name="" id="amount" /
                    >

                    <h1 className="fw-light mt-5">Select Pujari/Priest (Optional)</h1>
                    <p className="fw-light fs-5 mb-0">Select priest who you want the donetion to go through</p>
                    <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example">
                        <option selected></option>
                        <option value="1">Shree Mahadev Belive</option>
                        <option value="2">Shree Kumar Padhye</option>
                    </select>


                    <h1 className="fw-light mt-5">Select Intent</h1>
                    <p className="fw-light fs-5 mb-0">Purpose of donation</p>
                    <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example">
                        <option selected></option>
                        <option value="1">General donation</option>
                        <option value="2">Birthday</option>
                        <option value="3">Anniversary</option>
                        <option value="4">For Temple Development</option>
                    </select>

                    <h1 className="fw-light mt-5">Gotra of the donor if known (Optional)</h1>
                    <input type="text" className="form-control" placeholder="Gotra of the donor if known" /
                    >

                    <div className="form-check mt-5 mb-3">
                        <input className="form-check-input mt-3 mb-3" type="checkbox" value="" id="flexCheckDefault" />
                        <label className="form-check-label " for="flexCheckDefault">
                            <h1 className="fw-light">This deity is donor's Kuladevata (Optional)</h1>
                        </label>
                    </div>
                </div>

            </div>

            <div className="container-fluid mt-4  mb-5">
                <div className="container text-center">
                    <button className="btn  action-btn  btn-lg  rounded-pill begin-rounded end-rounded mx-2 responsive-style-btn-1">
                        Save info by Logging In
                    </button><button
                        className="btn action-btn  btn-lg rounded-pill  begin-rounded end-rounded  bg-dark-orange"  id='submit'>
                        Proceed to payment
                    </button>
                </div>
            </div>
        </div>



        
    )
}


export default Donate



{/* <script>
// Get the input field and the submit button
const inputField = document.querySelector('#inputField');
const submitButton = document.querySelector('#submitButton');

// Add an event listener to the submit button
submitButton.addEventListener('click', function() {
  // Get the value of the input field
  const inputValue = inputField.value;

  // Check if the input value is negative or has a point
  if (inputValue < 0 || inputValue.includes('.')) {
    // Show an alert
    alert('Please enter a positive whole number.');
  } else {
    // If the input value is positive and whole, you can add your further logic here
  }
});
</script> */}
