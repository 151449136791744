import React from 'react'

const History = (props) => {
  return (
    <div>
         <div class="container-fluid bg-dark-yellow add-temples">
        <div class="text-center mt-5 mb-4">
          <h1 class="fw-light">{props.title}</h1>
        </div>

       {/* <div class="container">
          <div class="row text-center">
            
            <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 mb-3">
              <div class="card mx-auto begin-rounded-1r  end-rounded-1r" style={{ width: "18rem" }}>
                <h5 class="card-title mt-2">{props.paragraph}</h5>
                <img src="./images/IMG_9308_1_HEIC.webp" class="card-img-top" alt="..." />
                <div class="card-body">
                  <h5 class="card-title card-heading">
                    {props.paragraph1}
                  </h5>
                  <p class="card-text card-paragraph">
                   {props.subtitle}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 mb-3">
              <div class="card  mx-auto  begin-rounded-1r  end-rounded-1r" style={{ width: "18rem" }}>
                <h5 class="card-title mt-2">{props.paragraph2}</h5>
                <img src="./images/templeID1_History_Event1_1.webp" class="card-img-top" alt="..." />
                <div class="card-body">
                  <h5 class="card-title  card-heading">
                    {props.paragraph3}
                  </h5>
                  <p class="card-text card-paragraph">
                   {props.subtitle1}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 mb-3">
              <div class="card mx-auto begin-rounded-1r  end-rounded-1r" style={{ width: "18rem" }}>
                <h5 class="card-title mt-2">{props.paragraph4}</h5>
                <img src="./images/IMG_9308_HEIC.webp" class="card-img-top" alt="..." />
                <div class="card-body">
                  <h5 class="card-title card-heading">
                    {props.paragraph5}
                  </h5>
                  <p class="card-text card-paragraph">
                  {props.subtitle1}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 mb-3">
              <div class="card mx-auto begin-rounded-1r  end-rounded-1r" style={{ width: "18rem" }}>
                <h5 class="card-title mt-2">{props.paragraph6}</h5>
                <img src="./images/IMG_9308_HEIC.webp" class="card-img-top" alt="..." />
                <div class="card-body">
                  <h5 class="card-title card-heading">
                    {props.paragraph7}
                  </h5>
                  <p class="card-text card-paragraph">
                  {props.subtitle1}
                  </p>
                </div>
              </div>
            </div>
          </div>
  </div>*/}
      </div>

    </div>
  )
}

export default History