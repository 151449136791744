import React from 'react'

const Card2 = (props) => {
  return (
    <div>
      <div class="text-center mt-5 mb-4">
        <h1 class="fw-light">{props.title}</h1>
      </div>

      <div class="container-fluid mt-5">
        <div class="container">
          <div class="row bg-white border border-dark text-center p-0 begin-rounded end-rounded mb-4">
            <div class="col-lg-2 col-md-2  img-center text-center px-0">
              <img src="./images/27.webp" alt="" class="begin-rounded me-5 image img-center" height="140px" width="180px" />
            </div>
            <div class="col-lg-8  col-md-8   text-center">
              <p class="fs-5 fw-normal trust-facilities-paragraph mt-3 mb-0">
                {props.paragraph}
              </p>
              <p class="mb-0 trust-facilities-paragraph">{props.paragraph1}</p>
              <p class="mb-0 trust-facilities-paragraph">{props.paragraph2}</p>
            </div>
            <div class="col-lg-2 col-md-2   rounded-end-3">
              <button class="btn mt-5 text-end bg-dark-orange bg-hover-dark btn begin-rounded end-rounded view-temple-btn mb-3" type="button">
                {props.subtitle}
              </button>
            </div>
          </div>

          <div class="row bg-white border border-dark text-center p-0 begin-rounded end-rounded mb-4">
            <div class="col-lg-2 col-md-2 img-center  text-center px-0">
              <img src="./images/27.jpg" alt="" class="begin-rounded me-5 image img-center"height="140px" width="180px" />
            </div>
            <div class="col-lg-8  col-md-8  text-center mb-0">
              <p class="fs-5 fw-normal trust-facilities-paragraph mt-3 mb-0">
                {props.paragraph3}
              </p>
              <p class="mb-0 trust-facilities-paragraph">{props.paragraph4}</p>
              <p class="mb-0 trust-facilities-paragraph">{props.paragraph5}</p>
            </div>
            <div class="col-lg-2 col-md-2   rounded-end-3">
              <button class="btn mt-5 text-end bg-dark-orange bg-hover-dark btn begin-rounded end-rounded view-temple-btn mb-3" type="button">
                {props.subtitle}
              </button>
            </div>
          </div>

          <div class="row bg-white border border-dark text-center p-0 begin-rounded end-rounded mb-4">
            <div class="col-lg-2 col-md-2 img-center  text-center px-0">
              <img src="./images/28.jpg" alt="" class="begin-rounded me-5 image img-center" height="140px" width="180px"  />
            </div>
            <div class="col-lg-8  col-md-8    text-center">
              <p class="fs-5 fw-normal trust-facilities-paragraph mt-3 mb-0">
                {props.paragraph6}
              </p>
              <p class="mb-0  trust-facilities-paragraph">{props.paragraph7}</p>
              <p class="mb-0  trust-facilities-paragraph">{props.paragraph8}</p>
            </div>
            <div class="col-lg-2 col-md-2   rounded-end-3">
              <button class="btn mt-5 text-end bg-dark-orange  bg-hover-dark btn begin-rounded end-rounded view-temple-btn mb-3" type="button">
                {props.subtitle}
              </button>
            </div>
          </div>
          <div class="row bg-white border border-dark text-center p-0 begin-rounded end-rounded mb-4">
            <div class="col-lg-2 col-md-2 img-center  text-center px-0">
              <img src="./images/29.jpg" alt="" class="begin-rounded me-5 image img-center" height="140px" width="180px"  />
            </div>
            <div class="col-lg-8  col-md-8    text-center">
              <p class="fs-5 fw-normal trust-facilities-paragraph mt-3 mb-0">
                {props.paragraph9}
              </p>
              <p class="mb-0  trust-facilities-paragraph">{props.paragraph10}</p>
              <p class="mb-0  trust-facilities-paragraph">{props.paragraph11}</p>
            </div>
            <div class="col-lg-2 col-md-2   rounded-end-3">
              <button class="btn mt-5 text-end bg-dark-orange  bg-hover-dark btn begin-rounded end-rounded view-temple-btn mb-3" type="button">
                {props.subtitle}
              </button>
            </div>
          </div>
          <div class="row bg-white border border-dark text-center p-0 begin-rounded end-rounded mb-4">
            <div class="col-lg-2 col-md-2 img-center  text-center px-0">
              <img src="./images/30.jpg" alt="" class="begin-rounded me-5 image img-center" height="140px" width="180px"  />
            </div>
            <div class="col-lg-8  col-md-8    text-center">
              <p class="fs-5 fw-normal trust-facilities-paragraph mt-3 mb-0">
                {props.paragraph12}
              </p>
              <p class="mb-0  trust-facilities-paragraph">{props.paragraph13}</p>
              <p class="mb-0  trust-facilities-paragraph">{props.paragraph14}</p>
            </div>
            <div class="col-lg-2 col-md-2   rounded-end-3">
              <button class="btn mt-5 text-end bg-dark-orange  bg-hover-dark btn begin-rounded end-rounded view-temple-btn mb-3" type="button">
                {props.subtitle}
              </button>
            </div>
          </div>
          <div class="row bg-white border border-dark text-center p-0 begin-rounded end-rounded mb-4">
            <div class="col-lg-2 col-md-2 img-center  text-center px-0">
              <img src="./images/31.avif" alt="" class="begin-rounded me-5 image img-center" height="140px" width="180px"  />
            </div>
            <div class="col-lg-8  col-md-8    text-center">
              <p class="fs-5 fw-normal trust-facilities-paragraph mt-3 mb-0">
                {props.paragraph15}
              </p>
              <p class="mb-0  trust-facilities-paragraph">{props.paragraph16}</p>
              <p class="mb-0  trust-facilities-paragraph">{props.paragraph17}</p>
            </div>
            <div class="col-lg-2 col-md-2   rounded-end-3">
              <button class="btn mt-5 text-end bg-dark-orange  bg-hover-dark btn begin-rounded end-rounded view-temple-btn mb-3" type="button">
                {props.subtitle}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Card2