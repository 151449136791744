import React from 'react'

const Article1 = (props) => {
    return (
      
            <div className="container-fluid bg-black">
                <div className="container">
                    <div className="row pt-5 pb-5 mx-auto">
                        <div className="col-lg-6">
                            <div className="section-1 px-3 text-white">
                                <h2 className="fw-light pt-3">{props.title}</h2>
                                <h1 className="fw-light min-ht">{props.body}</h1>
                                <button className="btn action-btn  mb-3 section-btn" type="button">{props.body2}</button>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="section-2 px-3 text-white">
                                <h2 className="fw-light pt-3">{props.title}</h2>
                                <h1 className="fw-light min-ht">{props.body1}</h1>
                                <button className="btn action-btn mb-3 section-btn" type="button">{props.body2}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Article1