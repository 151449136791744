import React from 'react'

const Section = (props) => {
    return (
        <div>
            <div className="container-fluid img-fluid top-img position-relative" id="">
                <div className="transparency position-absolute top-50 start-50 translate-middle">
                    <h1 className="m-3 transparency-font">
                        {props.title}
                    </h1>
                    <p>{props.body}</p>
                    <button type="submit" className="btn begin-rounded end-rounded view-temple-btn px-4 mb-3">
                        {props.body1}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Section