import React from 'react'
import { Link,  } from "react-router-dom";
const Card = (props) => {
    return (
        <div>
            <div className="container-fluid minht-500px">
                <div className="container">
                    <div className="row pb-4 text-center">
                        <div className="col-lg-3 col-md-6 col-sm-6 mt-4">
                            <div className="card top-rounded bottom-rounded">
                                <img src="images/ritual .webp" className="card-img-top icon-img" alt="..." />
                                <div className="card-body">
                                    <p className="card-text mht-2r">
                                        {props.title}
                                    </p>
                                    <button className="action-btn" type="submit">
                                        <a href="#" className="btn px-4 text-hover-white">{props.body}</a>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 mt-4">
                            <div className="card top-rounded bottom-rounded">
                                <img src="images/Kuldevata icon.webp" className="card-img-top icon-img" alt="..." />
                                <div className="card-body">
                                    <p className="card-text mht-2r">{props.title1}</p>
                                    <button className="action-btn" type="submit">
                                        <a href="#" className="btn px-4 text-hover-white">{props.body}</a>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 mt-4">
                            <div className="card top-rounded bottom-rounded">
                                <img src="images/3.webp" className="card-img-top icon-img" alt="..." />
                                <div className="card-body">
                                    <p className="card-text mht-2r">{props.title2}</p>
                                    <button className="action-btn" type="submit">
                                        <a href="#" className="btn px-4 text-hover-white">{props.body}</a>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 mt-4">
                            <div className="card top-rounded bottom-rounded">
                                <img src="images/temple icon.webp" className="card-img-top icon-img" alt="..." />
                                <div className="card-body">
                                    <p className="card-text mht-2r">
                                        {props.title3}
                                    </p>
                                    <button className="action-btn" type="submit">
                                        <a href="#" className="btn px-4 text-hover-white">{props.body}</a>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="container-fluid add-temples">
                <div className="container">
                    <p className="text-center m-3 heading">{props.sub}</p>
                    <div className="row text-center">
                        <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-4 mb-3">
                            <div className="card top-rounded bottom-rounded">
                                <img src="images/23.jpg" className="top-rounded" alt="..." />
                                <div className="card-body">
                                    <img src="images/44.webp" alt="..." height="50px"/>
                                    <h5>{props.subtitle}</h5>
                                    <p className="card-text">{props.subtitle1}</p>
                                    <button className="action-btn" type="submit">
                                    <Link to="/details1" >  <a href="#" className="btn px-4 text-hover-white">{props.body}</a></Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-4 mb-3">
                            <div className="card top-rounded bottom-rounded">
                                <img src="images/39.jpg" className="top-rounded" alt="..." />
                                <div className="card-body">
                                <img src="images/44.webp" alt="..." height="50px"/>
                                    <h5>{props.soham}</h5>

                                    <p className="card-text">{props.subtitle2}</p>
                                    <button className="action-btn" type="submit">
                                    <Link to="/details2"> <a href="#" className="btn px-4 text-hover-white">{props.body}</a></Link> 
                                    </button>
                                </div>
                            </div>
                        </div>
                       {/* <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-4 mb-3">
                            <div className="card top-rounded bottom-rounded">
                                <img src="images/TempleID1_Gallery_2_edited.webp" className="top-rounded" alt="..." />
                                <div className="card-body">
                                    <img src="images/Screen Shot 2023-12-06 at 12_06_06 PM.webp" alt="..." />
                                    <h5>{props.subtitle}</h5>

                                    <p className="card-text">{props.subtitle3}</p>
                                    <button className="action-btn" type="submit">
                                        <a href="#" className="btn px-4 text-hover-white">{props.body}</a>
                                    </button>
                                </div>
                            </div>
                            </div>*/}
                       {/* <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-4 mb-3">
                            <div className="card top-rounded bottom-rounded">
                                <img src="images/TempleID1_Gallery_2_edited.webp" className="top-rounded" alt="..." />
                                <div className="card-body">
                                    <img src="images/Screen Shot 2023-12-06 at 12_06_06 PM.webp" alt="..." />
                                    <h5>{props.subtitle}</h5>

                                    <p className="card-text">{props.subtitle4}</p>
                                    <button className="action-btn" type="submit">
                                        <a href="#" className="btn px-4 text-hover-white">{props.body}</a>
                                    </button>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card