import React from 'react'

const Card1 = (props) => {
  return (
    <div>
        <div class="container-fluid py-3 mt-5 bg-cream">
        <div class="text-center mt-3 mb-4">
          <h1 class="fw-light">{props.title}</h1>
        </div>

        <div class="container">
          <div class="row bg-white border border-dark text-center p-0 begin-rounded end-rounded mb-4">
            <div class="col-lg-2 col-md-2 img-center text-center px-0">
              <img src="./images/31.jpg" alt="" class="begin-rounded me-5  image img-center" height="140px" width="180px" />
            </div>
            <div class="col-lg-8 col-md-8">
              <p class="fs-5 fw-normal mt-3 text-center trust-facilities-paragraph ms-5">
               {props.paragraph}
              </p>
            </div>
            <div class="col-lg-2 col-md-2  rounded-end-3">
              <button class="btn mt-5 text-end bg-dark-orange bg-hover-dark btn begin-rounded end-rounded view-temple-btn mb-3" type="button">
                {props.subtitle}
              </button>
            </div>
          </div>

         {/*<div class="row bg-white border border-dark text-center p-0 begin-rounded end-rounded mb-4">
            <div class="col-lg-2 col-md-2 img-center  text-center px-0">
              <img src="./images/TempleID1_FAcility_2.webp" alt="" class="begin-rounded me-5 image img-center" />
            </div>
            <div class="col-lg-8  col-md-8  text-center mb-0">
              <p class="fs-5 fw-normal trust-facilities-paragraph mt-3 mb-0 ms-5">
                {props.paragraph1}
              </p>
            </div>
            <div class="col-lg-2 col-md-2  rounded-end-3">
              <button class="btn mt-5 text-end bg-dark-orange bg-hover-dark btn begin-rounded end-rounded view-temple-btn mb-3" type="button">
              {props.subtitle}
              </button>
            </div>
          </div>

          <div class="row bg-white border border-dark text-center p-0 begin-rounded end-rounded mb-4">
            <div class="col-lg-2 col-md-2 img-center  text-center px-0">
              <img src="./images/TempleID1_Activity_2.webp" alt="" class="begin-rounded me-5 image img-center" />
            </div>
            <div class="col-lg-8 col-md-8 text-center">
              <p class="fs-5 fw-normal mt-3  trust-facilities-paragraph">
               {props.paragraph2}
              </p>
            </div>
            <div class="col-lg-2 col-md-2   rounded-end-3">
              <button class="btn mt-5 text-end bg-dark-orange bg-hover-dark btn begin-rounded end-rounded view-temple-btn mb-3 " type="button">
              {props.subtitle}
              </button>
            </div>
          </div>*/}
        </div>
      </div>
    </div>
  )
}

export default Card1