import React from "react";
import { Link, Router } from "react-router-dom";
const Header = () => {
  return (
    <div>

        {/* <div className="row">
          <div className="col-md-3 logo-part mt-3">
            <img src="images/Logo.webp" alt="" />
          </div>
          <div className="col-md-6">
            <div className="mt-3 input-group mb-3 w-md-80-1">
              <input type="text" className="form-control begin-rounded" placeholder="Search Using Temple Diety or Location"
                aria-label="Search Temples" aria-describedby="basic-addon2" />
              <button className="btn btn-primary end-rounded btn-orange" id="basic-addon2">
                <i className="fa fa-search" aria-hidden="false"></i>
              </button>
            </div>
          </div>
          <div className="col-md-3">
            <h4>
              <button type="submit" className="user">
                <i className="fa fa-user"></i> &nbsp;Log In
              </button>

            </h4>
          </div>
        </div>  */}

        { <div className="container-fluid  px-0 bg-white">
          <nav className="navbar navbar-expand-lg bg-white ">
            <div className="container-fluid justify-content-between  pt-3  pb-3">
              <div className="d-flex">
                <a
                  className="navbar-brand me-2 mb-1 d-flex align-items-center"
                  href="#"
                >
                  <Link to="/" className=" nav-link">  <img
                    src="images/Logo.webp"
                  
                    alt=""
                    loading=""
                    maxWidth="100"
                    
                  /></Link>
                </a>
              </div>
              {/* w-auto my-auto   -- remove and add style - ht=3rem and width=500px */}
              <ul className="navbar-nav flex-row  d-md-flex">
                <form className="input-group   d-sm-flex  searchbar-ht-width">       
                  <input
                    autocomplete="off"
                    type="search"
                    className="form-control   top-rounded-searchbar  bottom-rounded-searchbar  bg-light-pink"
                    placeholder="Temple search by deity or location"
                    
                  />
                  <span className="input-group-text border-0  top-rounded-searchbar  bottom-rounded-searchbar search-bg-orange d-lg-flex  left-padding">
                    <i className="fas fa-search" style={{color:"white"}}></i>
                  </span>
                </form>
              </ul>
              <ul className="navbar-nav flex-row ">
                <a href="" className="fs-3 text-dark  remove-underline">
                  <i className="fa-sharp fa-solid fa-circle-user  mx-2"></i>Log In
                </a>
              </ul>
            </div>
          </nav>
        </div> }

      
      <div className="container-fluid bg-orange">
        <nav className="navbar navbar-expand-lg navbar-light bg-orange">
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to="/" className=" nav-link">
                    Home
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="temple.html"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Temples
                  </a>
                  <ul
                    className="dropdown-menu bg-yellow"
                    aria-labelledby="navbarDropdown"
                  >
                    <li className="">
                      <Link to="/details1" className="dropdown-item bg-yellow">
                        Velneshwar Mandir
                      </Link>
                    </li>
                    <li>
                      <Link to="/details2" className=" dropdown-item bg-yellow">
                        Vyadeshwar Mandir
                      </Link>
                    </li>
                    <li>
                      <Link to="/donate" className="dropdown-item bg-yellow">
                        Donate Page
                      </Link>
                    </li>
                    <li>
                      <Link to="/schedule" className="dropdown-item bg-yellow">
                        {" "}
                        Schedule & Events
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item">
                  <a className="nav-link"></a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Events and calendar
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Kuladevata
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Find Pujari/Priest
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
