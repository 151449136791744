import React from 'react'
import { Link, Router } from 'react-router-dom'
import Header from './Header'
import './Schedule.css';
const Schedule = () => {
    return (
        <div>
            <Header />
            <div class="container-fluid">
                <div class="container text-center">
                    <div class="next-table">
                        <ul class="nav nav-tabs orange-border mt-5" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="tab-bg-orange active " id="home-tab" data-bs-toggle="tab"
                                    data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                                    <h5 class="fw-normal">Mahashivratri (Current)</h5>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="tab-bg-orange " id="profile-tab" data-bs-toggle="tab"
                                    data-bs-target="#profile" type="button" role="tab" aria-controls="profile"
                                    aria-selected="false">
                                    <h5 class="fw-normal">Daily Rituals (Regular)</h5>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="tab-bg-orange " id="third-tab" data-bs-toggle="tab" data-bs-target="#third"
                                    type="button" role="tab" aria-controls="third" aria-selected="false">
                                    <h5 class="fw-normal">Upcoming Events</h5>
                                </button>
                            </li>
                            
                        </ul>
                        <div class="tab-content mt-5" id="myTabContent">
                            <div class="tab-pane fade show active text-center mx-auto" id="home" role="tabpanel"
                                aria-labelledby="home-tab">
                                <table class="table">
                                    <thead></thead>
                                    <tbody class="pt-5 pb-5">
                                        <tr class="table-warning">
                                            <th scope="row">
                                                <button class="btn btn-outline-dark mt-3 begin-rounded end-rounded">
                                                    08/03/24
                                                </button>
                                            </th>
                                            <td>
                                                <h4 class="fw-light pt-3 pb-3">
                                                    First Day of Mahashivratri
                                                </h4>
                                            </td>
                                        </tr>
                                        <tr class="table-warning">
                                            <th scope="row">
                                                <button class="btn btn-outline-dark mt-3 begin-rounded end-rounded">
                                                    09:00 AM
                                                </button>
                                            </th>
                                            <td>
                                                <h4 class="fw-light pt-3 pb-3">Morning arati</h4>
                                            </td>
                                        </tr>
                                        <tr class="table-warning">
                                            <th scope="row">
                                                <button class="btn btn-outline-dark mt-3 begin-rounded end-rounded">
                                                    11:00 AM
                                                </button>
                                            </th>
                                            <td>
                                                <h4 class="fw-light pt-3 pb-3">Open for public visits</h4>
                                            </td>
                                        </tr>
                                        <tr class="table-warning">
                                            <th scope="row">
                                                <button class="btn btn-outline-dark mt-3 begin-rounded end-rounded">
                                                    12:00 PM
                                                </button>
                                            </th>
                                            <td>
                                                <h4 class="fw-light pt-3 pb-3">Mahaarati and prasad</h4>
                                            </td>
                                        </tr>
                                        <tr class="table-warning">
                                            <th scope="row">
                                                <button class="btn btn-outline-dark mt-3 begin-rounded end-rounded">
                                                    09/03/24
                                                </button>
                                            </th>
                                            <td>
                                                <h4 class="fw-light pt-3 pb-3">
                                                    Second day of Mahashivratri
                                                </h4>
                                            </td>
                                        </tr>
                                        <tr class="table-warning">
                                            <th scope="row">
                                                <button class="btn btn-outline-dark mt-3 begin-rounded end-rounded">
                                                    12:00 PM
                                                </button>
                                            </th>
                                            <td>
                                                <h4 class="fw-light pt-3 pb-3">Mahaarati and prasad</h4>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="tab-pane fade text-start bg-light-cream rounded-3" id="profile" role="tabpanel"
                                aria-labelledby="profile-tab"></div>
                            <div class="tab-pane fade text-start bg-light-cream p-5 rounded-3" id="third" role="tabpanel"
                                aria-labelledby="third-tab">
                               <table class="table">
                                    <thead></thead>
                                    <tbody class="pt-5 pb-5">
                                        <tr class="table-warning">
                                            <th scope="row">
                                            <h4 class="fw-light pt-3 pb-3">
                                                22 July 2024
                                                </h4>
                                            </th>
                                            <td>
                                                <h4 class="fw-light pt-3 pb-3">
                                                    First Shravani Somvar
                                                </h4>
                                            </td>
                                        </tr>
                                        <tr class="table-warning">
                                            <th scope="row">
                                            <h4 class="fw-light pt-3 pb-3">
                                                    29 July 2024
                                                </h4>
                                            </th>
                                            <td>
                                                <h4 class="fw-light pt-3 pb-3">Shravani Somvar</h4>
                                            </td>
                                        </tr>
                                        <tr class="table-warning">
                                            <th scope="row">
                                            <h4 class="fw-light pt-3 pb-3">
                                                    5 August 2024
                                                </h4>
                                            </th>
                                            <td>
                                                <h4 class="fw-light pt-3 pb-3">Shravani Somvar</h4>
                                            </td>
                                        </tr>
                                        <tr class="table-warning">
                                            <th scope="row">
                                            <h4 class="fw-light pt-3 pb-3">
                                                    12 August 2024
                                                </h4>
                                            </th>
                                            <td>
                                                <h4 class="fw-light pt-3 pb-3">Shravani Somvar</h4>
                                            </td>
                                        </tr>
                                        <tr class="table-warning">
                                            <th scope="row">
                                            <h4 class="fw-light pt-3 pb-3">
                                                    19 August 2024
                                                </h4>
                                            </th>
                                            <td>
                                                <h4 class="fw-light pt-3 pb-3">
                                                    Shravani Somvar
                                                </h4>
                                            </td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Schedule