import React from "react";

const Temple1 = (props) => {
  return (
    <div>
      {
        <div className="container mt-5">
        <h1 className="fw-light">{props.title}</h1>
        <div className="row">
          <div className="col-md-4 text-center my-5">
            <img
              src="./images/12.jpg"
              alt=""
              width="140px"
              height="140px"
              class="rounded-circle"
            />
          </div>
          <div className="col-md-6">
            <h2 className="fw-light">{props.heading}</h2>
            <p className="fs-4  fw-light">{props.paragraph}</p>
            <p className="fs-5  fw-light">{props.paragraph1}</p>
            <p className="fs-5  fw-light">{props.paragraph2}</p>
            <p className="fs-5  fw-light">{props.paragraph3}</p>
            <p className="fs-5  fw-light">{props.paragraph4}</p>
            <button className="btn btn-rounded bg-hover-dark mx-2 btn action-btn">
            {props.subtitle}
            </button>
            <button className="btn btn-rounded bg-hover-dark mx-2 btn action-btn">
            {props.subtitle1}
            </button>
          </div>
        </div>
      </div>
      }

      
    </div>
  );
};

export default Temple1;
