import React from 'react'
import { Link, Router } from 'react-router-dom'
import Header from './Header'
import './Details1.css';
import TopSection from './Topsection';
import Carousel from './Carousel';
import Temple1 from './Temple1';
import Temple2 from './Temple2';
import Card1 from './Card1';
import Card2 from './Card2';
import History from './History';
const Deatils1 = () => {
  return (
    <div>
      <Header />
      <TopSection title="Velneshwar Mandir" body="Velneshwar, Ratnagiri, Maharashtra" body1="Todays timings: 6.00 AM - 9.30 PM" subtitle="Contact Pujari" subtitle1=" Donate" subtitle2=" Kuldevata Info"  />
      <Carousel title="Gallery" title1="Daily Schedule" title2="Main Events" data="1" data1="6:00 AM" data2="Morning Kakad Arti" data3="1" data4="8 March 2024" data5="Mahashivratri" data6="2" data7="22 July 2024" data8="FIrst Shravani Somvar" data9="3" data10="29 July 2024" data11="Shravani Somvar" data12="4" data13="5 August 2024" data15="5" data16="12 August 2024" data17="6" data18="19 August 2024"/>
      <Temple1 title="Main Temple" heading="Mahadev/ Shankar - Shree Velneshwar" paragraph="Pooja Services offered-" paragraph1="Abhishek" paragraph2="Early morning pooja/Kakad Aarti" paragraph3="Laghurudra" paragraph4="Milk Abhishek"subtitle="Contact Pujari" subtitle1="Donate"/>
      <Temple2 title="Other Temples" heading="Shani Mandir" paragraph="Pooja Services offered-" paragraph1="Abhishek" paragraph2="Naivedya/Offerings" subtitle="Contact Pujari" subtitle1="Donate" subtitle2="Main Events"/>
      <Card1 title="Temple Trust Facilities" paragraph="Velneshwar Bhakta Niwas" paragraph1="Onsite facilities for keeping footwear, drinking water, prasad at 12:30PM" paragraph2=" Bhagvatgeeta classes" subtitle="Contact"/>
      <Card2 title="Nearby Recommended Facilities" paragraph="Nisarg Resort"paragraph1="18.1 KM" paragraph2="Comfortable hotel near guhaghar beach" paragraph3="Shreel Niwas" paragraph4="5.6KM" paragraph5="AC, Non AC Rooms" paragraph6=" Kinara velneshwar beach hosue"paragraph7="0.85 KM" paragraph8="Beach Resort Hotel in Velneshwar" paragraph9="Velneshwar Beach Resort" paragraph10="0.4KM" paragraph11="Beach Resort" paragraph12="Shantai by the lake" paragraph13="16KM" paragraph14="Hotel" paragraph15="Hotel Nakshatra" paragraph16="18.5KM" paragraph17="Combination of elegance and homely atmosphere" subtitle="Contact"/>
      <History title="History" paragraph="Prehistoric" paragraph1="Temple Establishment" subtitle=" Event Discription (Character limit 300 characters)" paragraph2="1640 AD" paragraph3="Temple destruction" subtitle1=" Click here to edit the text and include the information you would like to feature." paragraph4="12 March 2021" paragraph5="New Temple Constructions" paragraph6="14 Mar 2024" paragraph7="Library and dormitory constrction"/>
                   
    </div>
  )
}

export default Deatils1