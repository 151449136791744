import React from "react";

const Footer = (props) => {
  return (
    <div>
      <div className="container-fluid bg-yellow pt-3">
        <div className="row">
          <div className="text-center">
            <h1 className="fw-light">{props.title}</h1>
            <p className="fs-5 fw-light">{props.body}</p>
            {/* <div className="mt-3 input-group mb-3 w-md-80-1 w-50 mx-auto mb-5">
                            <input type="text" className="form-control begin-rounded" placeholder="Your Email Here"
                                aria-label="Search Temples" aria-describedby="basic-addon2" />
                            <button className="btn btn-primary end-rounded btn-orange" id="basic-addon2">
                               {props.subtitle}
                            </button>
                        </div> */}
           

            <form
              className="input-group  mx-auto  mb-5 d-sm-flex ft-searchbar-ht-width "
             
            >
              <input
                autocomplete="off"
                type="search"
                className="form-control   top-rounded-searchbar  bottom-rounded-searchbar"
                placeholder="Your Email Here"
              />
              <span className="input-group-text border-0   top-rounded-searchbar  bottom-rounded-searchbar  search-bg-orange  d-lg-flex">
                <h6 className="text-center  my-auto  mx-auto  text-white">
                  Subscribe
                </h6>
              </span>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
