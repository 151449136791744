import React from 'react'

const Carousel = (props) => {
  return (
    <div>
         <div class="container-fluid">
        <div class="container">
          <div class="row text-center">
            <div class="col-lg-6 mt-5">
              <h3 class="fw-light">{props.title}</h3>

              <div id="carouselExampleFade" class="carousel slide carousel-fade mt-5" data-bs-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="./images/17.jpg" class="d-block mx-auto img-dimension" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src="./images/18.jpg" class="d-block mx-auto img-dimension" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src="./images/19.jpg" class="d-block mx-auto img-dimension" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src="./images/20.jpg" class="d-block mx-auto img-dimension" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src="./images/21.webp" class="d-block mx-auto img-dimension" alt="..." />
                  </div>
                  
                  <div class="carousel-item">
                    <img src="./images/22.jpg" class="d-block mx-auto img-dimension" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src="./images/23.jpg" class="d-block mx-auto img-dimension" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src="./images/24.jpg" class="d-block mx-auto img-dimension" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src="./images/25.jpg" class="d-block mx-auto img-dimension" alt="..." />
                  </div>
                  <div class="carousel-item">
                    <img src="./images/26.jpg" class="d-block mx-auto img-dimension" alt="..." />
                  </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade"
                  data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true" style={{ color: "black" }}></span>
                  <span class="visually-hidden"></span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade"
                  data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true" style={{ color: "black" }}></span>
                  <span class="visually-hidden"></span>
                </button>
              </div>
            </div>
            <div class="col-lg-6 next-table">
              <ul class="nav nav-tabs orange-border mt-5" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="tab-bg-orange active mx-2" id="home-tab" data-bs-toggle="tab" data-bs-target="#home"
                    type="button" role="tab" aria-controls="home" aria-selected="true">
                    {props.title1}
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="tab-bg-orange  mx-2" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile"
                    type="button" role="tab" aria-controls="profile" aria-selected="false">
                    {props.title2}
                  </button>
                </li>
              </ul>
              <div class="tab-content mt-5" id="myTabContent">
                <div class="tab-pane fade show active text-start" id="home" role="tabpanel" aria-labelledby="home-tab">


                  <table class="table">
                    <thead>

                    </thead>
                    <tbody class="pt-5 pb-5">
                      <tr class="table-warning">
                        <th scope="row">
                          <h6 class="fw-normal pt-3 pb-3">{props.data}</h6>
                        </th>
                        <td>
                          <h6 class="fw-normal pt-3 pb-3">{props.data1}</h6>
                        </td>
                        <td>
                          <h6 class="fw-normal pt-3 pb-3">{props.data2}</h6>
                        </td>
                      </tr>
                      <tr class="table-warning">
                        <th scope="row">
                          <h6 class="fw-normal pt-3 pb-3"></h6>
                        </th>
                        <td>
                          <h6 class="fw-normal pt-3 pb-3"></h6>
                        </td>
                        <td>
                          <h6 class="fw-normal pt-3 pb-3"></h6>
                        </td>
                      </tr>
                      <tr class="table-warning" style={{ padding: "2px;" }}>
                        <th scope="row">
                          <h6 class="fw-normal pt-3 pb-3"></h6>
                        </th>
                        <td>
                          <h6 class="fw-normal pt-3 pb-3"></h6>
                        </td>
                        <td>
                          <h6 class="fw-normal pt-3 pb-3"></h6>
                        </td>
                      </tr>
                    </tbody>
                  </table>


                </div>
                <div class="tab-pane fade text-start" id="profile" role="tabpanel" aria-labelledby="profile-tab">

                <table class="table">
                    <thead>

                    </thead>
                    <tbody class="pt-5 pb-5">
                      <tr class="table-warning">
                        <th scope="row">
                          <h6 class="fw-normal pt-3 pb-3">{props.data3}</h6>
                        </th>
                        <td>
                          <h6 class="fw-normal pt-3 pb-3">{props.data4}</h6>
                        </td>
                        <td>
                          <h6 class="fw-normal pt-3 pb-3">{props.data5}</h6>
                        </td>
                      </tr>
                      <tr class="table-warning">
                        <th scope="row">
                          <h6 class="fw-normal pt-3 pb-3">{props.data6}</h6>
                        </th>
                        <td>
                          <h6 class="fw-normal pt-3 pb-3">{props.data7}</h6>
                        </td>
                        <td>
                          <h6 class="fw-normal pt-3 pb-3">{props.data8}</h6>
                        </td>
                      </tr>
                      <tr class="table-warning">
                        <th scope="row">
                          <h6 class="fw-normal pt-3 pb-3">{props.data9}</h6>
                        </th>
                        <td>
                          <h6 class="fw-normal pt-3 pb-3">{props.data10}</h6>
                        </td>
                        <td>
                          <h6 class="fw-normal pt-3 pb-3">{props.data11}</h6>
                        </td>
                      </tr>
                      <tr class="table-warning">
                        <th scope="row">
                          <h6 class="fw-normal pt-3 pb-3">{props.data12}</h6>
                        </th>
                        <td>
                          <h6 class="fw-normal pt-3 pb-3">{props.data13}</h6>
                        </td>
                        <td>
                          <h6 class="fw-normal pt-3 pb-3">{props.data11}</h6>
                        </td>
                      </tr>
                      <tr class="table-warning">
                        <th scope="row">
                          <h6 class="fw-normal pt-3 pb-3">{props.data15}</h6>
                        </th>
                        <td>
                          <h6 class="fw-normal pt-3 pb-3">{props.data16}</h6>
                        </td>
                        <td>
                          <h6 class="fw-normal pt-3 pb-3">{props.data11}</h6>
                        </td>
                      </tr>
                      <tr class="table-warning">
                        <th scope="row">
                          <h6 class="fw-normal pt-3 pb-3">{props.data17}</h6>
                        </th>
                        <td>
                          <h6 class="fw-normal pt-3 pb-3">{props.data18}</h6>
                        </td>
                        <td>
                          <h6 class="fw-normal pt-3 pb-3">{props.data11}</h6>
                        </td>
                      </tr>
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Carousel