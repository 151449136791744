import React from 'react'
import { Link } from "react-router-dom";
const Topsection = (props) => {
  return (
    <div>
          <div class="container-fluid bg-yellow">
        <div class="container">
          <div class="text-center">
          <img src="images/44.webp" alt="..." height="50px"/>
            <h1 class="fs-1 fw-normal">{props.title}</h1>
            <p>{props.body}</p>
            <p>{props.body1}</p>
            <button class="btn btn action-btn w-30 mx-2 mb-3 bg-hover-dark" type="button">
              {props.subtitle}</button><button class="btn action-btn w-30 mx-2 mb-3 bg-hover-dark" type="button">
              <Link to="/donate" className='link'>{props.subtitle1}</Link></button><button class="btn action-btn w-30 mx-2 mb-3 bg-hover-dark" type="button">
             {props.subtitle2}</button><button class="btn action-btn w-30 mx-2 mb-3 bg-hover-dark" type="button">
              <a href='https://maps.app.goo.gl/cGPCKa5ZDtAsVYba7' className='link'>Google Map</a>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Topsection