import React from 'react'
import { useState } from 'react'
import { Link, Router } from 'react-router-dom'
import Header from './Header'
import Section from './Section'
import Card from './Card'
import Article1 from './Article1'
import Article2 from './Article2'
import Footer from './Footer'

const Home = () => {
  return (
    <div>
      <Header />
      <Section title="Stay connected to your roots, explore, and contribute" body="Get the most updated information on your temples" body1="View Temples" />
      <Card title="Pooja, Abhishek and other rituals" title1="Known data on Kuladevata" title2="Hindu Calendar and Main Events" title3="Explore temples based on location or deity" body="More" sub="Recently added temples" subtitle="Velneshwar Temple" subtitle1="Velneshwar,Ratnagiri,Maharashtra." soham="Vyadeshwar Temple" subtitle2="Guhagar,Ratnagiri,Maharashtra." subtitle3="city3" subtitle4="city4" />
      <Article1 title="Explore" body="Deities and Family tree" body1="Interesting stories and  beliefs about temples" body2="Learn More" />
      <Article2 text="Request to join" title="Do you wish to see your temple here?" body="We are currently in the process of onboarding temples from multiple locations in India." subtitle=" Share link with temple trust" />
      <Footer title="Newsletter" body="Sign up to receive updates" subtitle=" Subscribe" />
    </div>
  )
}

export default Home