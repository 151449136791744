import React from 'react'
import { Link, Router } from 'react-router-dom'
import Header from './Header'
import './Details2.css';
const Details2 = () => {
    return (
        <div>
            <Header />
            <div className="container-fluid add-temples">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 text-center">
                        <img src="images/44.webp" alt="..." height="110px" width="110px"/>
                            <button type="button"
                                className="d-block mx-auto btn begin-rounded end-rounded view-temple-btn px-4 mb-3"><a className='link' href='https://maps.app.goo.gl/WiaXuzpsM8bQkYrR8'>Map</a></button>
                        </div>
                        <div className="col-md-5 text-center">
                            <h1 className="fw-light mt-4">Vyadeshwar Mandir</h1>
                            <p className="fw-light"> Guhagar, Ratnagiri, Maharashtra </p>
                            <p className="fw-light">Todays timings: 6:30 AM - 8:00 PM</p>

                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-4">
                <div className="container">
                    <div className="row">
                        <h1 className="fw-light text-center">Deities/देवता</h1>
                    </div>
                    <div className="row py-5 mt-5 border border-2 top-rounded bottom-rounded">
                        <div className="col-md-3 text-center">
                            <img src="images/PHOTO-2024-01-14-00-07-20.webp" alt="" className="rounded-circle" width="140px" height="140px" />
                        </div>
                        <div className="col-md-9 text-left">
                            <h2 className="fw-light ms-2">Mahadev/ Shankar - Shree Vyadeshwar</h2><br></br>
                            <button className="btn btn-rounded bg-hover-dark btn action-btn px-2 py-2 bs-btn m-2" type="button">
                                Contact Pujari</button>
                            <button type="button"
                                className="btn btn-rounded bg-hover-dark btn action-btn px-2 py-2 bs-btn m-2">
                                    <Link to="/donate" className='link'>Donate</Link></button>
                            <button type="button" className="btn btn-rounded bg-hover-dark btn action-btn px-2 py-2 bs-btn m-2">Pooja
                                Services</button>
                            <button type="button"
                                className="btn btn-rounded bg-hover-dark btn action-btn px-2 py-2 bs-btn m-2">Schedule/Events</button>
                            <button type="button" className="btn btn-rounded bg-hover-dark btn action-btn px-2 py-2 bs-btn m-2">Kuladevta
                                Info</button>
                        </div>
                    </div>
                  { /* <div className="row py-5 mt-5 border border-2 top-rounded bottom-rounded">
                        <div className="col-md-3 text-center">
                            <img src="images/PHOTO-2024-01-14-00-07-20.webp" alt="" className="rounded-circle" height="140px" width="140px" />
                        </div>
                        <div className="col-md-9">
                            <h2 className="fw-light ms-2">Shani Dev</h2><br></br>
                            <button type="button" className="btn btn-rounded bg-hover-dark btn action-btn px-2 py-2 bs-btn m-2">Contact
                                Pujari</button>
                            <button type="button"
                                className="btn btn-rounded bg-hover-dark btn action-btn px-2 py-2 bs-btn m-2">
                                    <Link to="/donate" className='link'>Donate</Link></button>
                            <button type="button"
                                className="btn btn-rounded bg-hover-dark btn action-btn px-2 py-2 bs-btn m-2">Pooja/Abhishek</button>
                            <button type="button"
                                className="btn btn-rounded bg-hover-dark btn action-btn px-2 py-2 bs-btn m-2">Events</button>
                            <button type="button" className="btn btn-rounded bg-hover-dark btn action-btn px-2 py-2 bs-btn m-2">Kuladevta
                                Info</button>
                        </div>
    </div>*/}
                    <div className="row mt-5">
                        <h1 className="fw-light text-center">Gallery</h1>
                        <div className="col-md-12">
                            <div id="carouselExampleFade" className="carousel slide carousel-fade mt-5" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src="./images/32.jpg" className="d-block mx-auto img-dimension" alt="..." height="600px"
                                            width="800px" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="./images/33.jpg" className="d-block mx-auto img-dimension" alt="..." height="600px"
                                            width="800px" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="./images/34.jpg" className="d-block mx-auto img-dimension" alt="..." height="600px"
                                            width="800px" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="./images/35.jpg" className="d-block mx-auto img-dimension" alt="..." height="600px"
                                            width="800px" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="./images/36.jpg" className="d-block mx-auto img-dimension" alt="..." height="600px"
                                            width="800px" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="./images/37.jpg" className="d-block mx-auto img-dimension" alt="..." height="600px"
                                            width="800px" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="./images/38.jpg" className="d-block mx-auto img-dimension" alt="..." height="600px"
                                            width="800px" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="./images/39.jpg" className="d-block mx-auto img-dimension" alt="..." height="600px"
                                            width="800px" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="./images/40.jpg" className="d-block mx-auto img-dimension" alt="..." height="600px"
                                            width="800px" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="./images/41.jpg" className="d-block mx-auto img-dimension" alt="..." height="600px"
                                            width="800px" />
                                    </div>
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade"
                                    data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true" style={{ color: "black" }}></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade"
                                    data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true" style={{ color: "black" }}  ></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-5 bg-skin p-5 bg-cream">
                <div className="container">
                    <div className="row">
                        <h1 className="fw-light text-center">Temple Trust Facilities</h1>
                    </div>
                    {/*
                    <div className="row mt-5 bg-white border border-dark p-0 begin-rounded end-rounded mb-4">
                        <div className="col-md-2 col-lg-2  px-0 ps-0  ">
                            <div className=" text-center img-center">
                                <img src="images/TempleID1_Facility1_1.webp" alt="" className=" begin-rounded image me-5 img-center" />
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-7 text-center">
                            <h4 className="fw-light mt-4 ms-5">Dormitory - Facility of devotees to stay up to 3 days</h4>

                        </div>
                        <div className="col-md-3 col-lg-3 text-center bg-white begin-rounded end-rounded">
                            <button type="button" className="btn begin-rounded end-rounded view-temple-btn px-4 mb-3 mt-5 ">Contact</button>
                        </div>
                   </div>*/}
                    {/*<div className="row mt-3 bg-white border border-dark  p-0 begin-rounded end-rounded mb-4">
                        <div className="col-md-2 col-lg-2 ps-0">
                            <div className="text-center img-center">
                                <img src="images/TempleID1_FAcility_2.webp" alt="" className=" begin-rounded image me-5 img-center" />
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-7 text-center">
                            <h4 className="fw-light mt-4 card-heading ms-5">Onsite facilities for keeping footwear, drinking water, prasad at 12:30
                                PM</h4>

                        </div>
                        <div className="col-md-3 col-lg-3 text-center bg-white begin-rounded end-rounded">
                            <button type="button" className="btn begin-rounded end-rounded view-temple-btn px-4 mb-3 mt-5">Contact</button>
                        </div>
                </div>*/}
                    {/*<div className="row mt-3 bg-white border border-dark p-0 begin-rounded end-rounded mb-4">
                        <div className="col-md-2 col-lg-2 ps-0 ">
                            <div className="text-center img-center">
                                <img src="images/TempleID1_Activity_2.webp" alt="" className="begin-rounded image me-5 img-center" />
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-7 text-center">
                            <h4 className="fw-light mt-4 card-heading">Bhagvatgeeta classes</h4>

                        </div>
                        <div className="col-md-3 col-lg-3 text-center bg-white  begin-rounded end-rounded">
                            <button type="button" className="btn begin-rounded end-rounded view-temple-btn px-4 mb-3 mt-5">Contact</button>
                        </div>
            </div>*/}
                </div>
            </div>

            <div className="container-fluid mt-5">
                <div className="container">
                    <div className="row">
                        <h1 className="fw-light text-center">Nearby Recommended Facilities</h1>
                    </div>
                    <div className="row mt-5 bg-white border border-dark p-0 begin-rounded end-rounded mb-4 ">
                        <div className="col-md-2 col-lg-2 ps-0  ">
                            <div className="text-center img-center">
                                <img src="images/42.jpg" alt="" className="begin-rounded image me-5 img-center" height="140px" width="180px"/>
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-7 text-center">
                            <h4 className="fw-bold mt-2 mt-4">Nisarg Hotel</h4>
                            <h5 className="fw-light h5 ms-5">1.1 KM , 2-Star Hotel</h5>
                        </div>
                        <div className="col-md-3 col-lg-3 text-center end-rounded">
                            <button type="button" className="btn begin-rounded end-rounded view-temple-btn px-4 mb-3 mt-5">Contact</button>
                        </div>
                    </div>
                    <div className="row mt-3 bg-white border border-dark  p-0 begin-rounded end-rounded mb-4">
                        <div className="col-md-2 col-lg-2 ps-0">
                            <div className="text-center img-center">
                                <img src="images/43.jpg" alt="" className="begin-rounded image me-5 img-center" height="140px" width="180px" />
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-7 text-center">
                            <h4 className="fw-bold mt-2 mt-4"> Hotel sushegad</h4>
                            <h5 className="fw-light h5">2.9 KM , 3-star hotel offers room service</h5>
                        </div>
                        <div className="col-md-3 col-lg-3 text-center  end-rounded">
                            <button type="button" className="btn begin-rounded end-rounded view-temple-btn px-4 mb-3 mt-5">Contact</button>
                        </div>
                    </div>
                    <div className="row mt-3 bg-white border border-dark  p-0 begin-rounded end-rounded mb-4 ">
                        <div className="col-md-2 col-lg-2 ps-0  ">
                            <div className="text-center img-center">
                                <img src="images/44.jpg" alt="" className="begin-rounded image me-5 img-center"height="140px" width="180px" />
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-7 text-center">
                            <h4 className="fw-bold mt-2 trust-facilities-heading mt-4">Sea Shell Home Stay Guhagar</h4>
                            <h5 className="fw-light h5">0.75KM , 5-star hotel</h5>
                        </div>
                        <div className="col-md-3 col-lg-3 text-center end-rounded">
                            <button type="button" className="btn begin-rounded end-rounded view-temple-btn px-4 mb-3 mt-5">Contact</button>
                        </div>
                    </div>
                    <div className="row mt-3 bg-white border border-dark  p-0 begin-rounded end-rounded mb-4 ">
                        <div className="col-md-2 col-lg-2 ps-0  ">
                            <div className="text-center img-center">
                                <img src="images/45.jpg" alt="" className="begin-rounded image me-5 img-center"height="140px" width="180px" />
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-7 text-center">
                            <h4 className="fw-bold mt-2 trust-facilities-heading mt-4"> Seawinds Hotel Guhagar

</h4>
                            <h5 className="fw-light h5">0.75KM , AC,NON-AC Rooms Available</h5>
                        </div>
                        <div className="col-md-3 col-lg-3 text-center end-rounded">
                            <button type="button" className="btn begin-rounded end-rounded view-temple-btn px-4 mb-3 mt-5">Contact</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="container-fluid  add-temples">
                <div className="container">
                    <div className="row ">
                        <h1 className="fw-light text-center pt-5 pb-5">History</h1>
                    </div>
                    { <div className="row m-0  text-center">

                        <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 mb-3">
                            <div className="card mx-auto begin-rounded-1r  end-rounded-1r" style={{ width: "18rem" }}>
                                <div className="card-body">
                                    <h5 className="card-title mt-2">Prehistoric</h5>
                                    <img src="images/IMG_9308_HEIC.webp" className="card-img-top" alt="..." />
                                    <h5 className="card-title card-heading">Temple Establishment</h5>
                                    <p className="card-text card-paragraph"> Event Discription (Character limit 300 characters)</p>

                                </div>
                            </div>
                        </div>


                        <div className="col-md-4 col-lg-4 col-xl-3">
                            <div className="card mb-4">

                                <div className="card-body">
                                    <h5 className="card-title">1640 AD</h5>
                                    <img src="images/templeID1_History_Event1_1.webp" className="card-img-top" alt="..." />
                                    <h5 className="card-title mt-3">Temple destruction</h5>
                                    <p className="card-text mt-3"> Click here to edit the text and include the information you would like to
                                        feature.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-xl-3">
                            <div className="card mb-4">

                                <div className="card-body">
                                    <h5 className="card-title">12 March 2021</h5>
                                    <img src="images/IMG.webp" className="card-img-top" alt="..." />
                                    <h5 className="card-title mt-3">New Temple Constructions</h5>
                                    <p className="card-text mt-3"> Click here to edit the text and include the information you would like to
                                        feature.</p>

                                </div>
                            </div>

                        </div>
                        <div className="col-md-4 col-lg-4 col-xl-3 ">
                            <div className="card mb-4">

                                <div className="card-body">
                                    <h5 className="card-title">14 Mar 2024</h5>
                                    <img src="images/IMG.webp" className="card-img-top" alt="..." />
                                    <h5 className="card-title mt-3">Library and dormitory constrction</h5>
                                    <p className="card-text mt-3"> Click here to edit the text and include the information you would like to
                                        feature.</p>

                                </div>
                            </div>

                        </div>
                    </div> }
                </div>
            </div> */}



<div class="container-fluid bg-dark-yellow">
    <div class="text-center mt-5 mb-4">
      <h1 class="fw-light">History</h1>
    </div>

   {/*<div class="container">
      <div class="row text-center">
        <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 mb-3">
          <div class="card mx-auto begin-rounded-1r  end-rounded-1r" style={{width: "18rem"}}>
            <h5 class="card-title mt-2">Prehistoric</h5>
            <img src="./images/IMG_9308_1_HEIC.webp" class="card-img-top" alt="..."/>
            <div class="card-body">
              <h5 class="card-title card-heading">
              Temple Establishment
              </h5>
              <p class="card-text card-paragraph">
                Event Discription (Character limit 300 characters)
              </p>
            </div>
          </div>
        </div>

        <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 mb-3">
          <div class="card  mx-auto  begin-rounded-1r  end-rounded-1r" style={{width: "18rem"}}>
            <h5 class="card-title mt-2">1640 AD</h5>
            <img src="./images/templeID1_History_Event1_1.webp" class="card-img-top" alt="..."/>
            <div class="card-body">
              <h5 class="card-title  card-heading">
                Temple destruction
              </h5>
              <p class="card-text card-paragraph">
                Click here to edit the text and include the information you
                would like to feature.
              </p>
            </div>
          </div>
        </div>

        <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 mb-3">
          <div class="card mx-auto begin-rounded-1r  end-rounded-1r" style={{width: "18rem"}}>
            <h5 class="card-title mt-2">12 March 2021</h5>
            <img src="./images/IMG_9308_HEIC.webp" class="card-img-top" alt="..."/>
            <div class="card-body">
              <h5 class="card-title card-heading">
                New Temple Constructions
              </h5>
              <p class="card-text card-paragraph">
                Click here to edit the text and include the information you
                would like to feature.
              </p>
            </div>
          </div>
        </div>

        <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 mb-3">
          <div class="card mx-auto begin-rounded-1r  end-rounded-1r" style={{width: "18rem"}}>
            <h5 class="card-title mt-2">14 Mar 2024</h5>
            <img src="./images/IMG_9308_HEIC.webp" class="card-img-top" alt="..."/>
            <div class="card-body">
              <h5 class="card-title card-heading">
                Library and dormitory constrction
              </h5>
              <p class="card-text card-paragraph">
                Click here to edit the text and include the information you
                would like to feature.
              </p>
            </div>
          </div>
        </div>
      </div>
        </div>*/}
  </div>
        </div>
    )
}

export default Details2